
































import ChatModel from "@/api/chat.model";
import corporateClientModel from "@/api/corporate_client.model";
import { ChatChannelStatus } from "@/interfaces/chat";
import ReportChatChannelModal from "@/layouts/components/ReportChatChannelModal.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";

@Component({
	components: { EntityCrud, ReportChatChannelModal },
})
export default class ChatChannelReport extends Vue {
	model = ChatModel;
	corporateClientModel = corporateClientModel;
	title = "";
	isReadOnly: boolean = true;
	defaultEntity = {};
	kind = "chatChannelTable";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowHeaderName = false;

	shouldGoToPreviousPageAfterRemove = false;

	chatChannelModalIsOpen: boolean = false;
	eventType: string = "";
	chatChannel: any = {};

	tableColumns = [
		{
			name: "Telefone",
			key: "phoneNumber",
		},
		{
			key: "user.name",
			nameComplement: "user.lastName",
			name: "Usuário",
		},
		{
			key: "user.id",
			kind: "invisible",
		},
		{
			key: "mainMember.name",
			nameComplement: "mainMember.lastName",
			name: "Cliente principal vinculado",
		},
		{
			key: "mainMember.id",
			kind: "invisible",
		},
		{
			key: "corporateClient.name",
			name: "Cliente corporativo",
			hasSelect: true,
			options: [{}],
		},
		{
			key: "status",
			name: "Status",
			hasSelect: true,
			options: [
				{ name: "Aberto", value: "OPENED" },
				{ name: "Encerrado", value: "CLOSED" },
			],
			translated: this.formatStatusName,
		},
		{
			key: "lastChatMessageCreatedDateDifference",
			name: "Em espera (horas)",
		},
		{
			name: "Ações",
			kind: "actions",
			actions: [
				{ icon: "person", eventName: "openClientProfile", type: "VueIcon", style: "h3" },
				{ icon: "arrow-left-right", eventName: "transferChannel", type: "VueIcon" },
				{
					icon: "chat-left-dots",
					eventName: "showChannelHistory",
					type: "VueIcon",
				},
				{ icon: "check2", eventName: "closeChannel", type: "VueIcon", style: "h3" },
			],
		},
	];

	get currentId() {
		return this.$route.params.id;
	}

	async mounted() {
		const foundColumn = this.tableColumns.find(tableColumn => tableColumn.key === "corporateClient.name");
		if (foundColumn) {
			const corporateClients = await corporateClientModel.get("");
			foundColumn.options = corporateClients.map((corporateClient: any) => {
				return { name: corporateClient.name, value: corporateClient.id };
			});
		}
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	titleDictionary(
		title: keyof {
			transferChannel: string;
			showChannelHistory: string;
			closeChannel: string;
			openClientProfile: string;
		},
	) {
		const dictionary = {
			transferChannel: "Transferir conversa",
			showChannelHistory: "Histórico da conversa",
			closeChannel: "Encerrar conversa",
			openClientProfile: "Abrir perfil do cliente",
		};

		return dictionary[title];
	}

	actionsEvent(event: "transferChannel" | "showChannelHistory" | "closeChannel" | "openClientProfile", row: any) {
		this.title = this.titleDictionary(event);
		this.chatChannel = row;
		if (event === "openClientProfile" && this.chatChannel["mainMember.id"]) {
			this.$router.push({ name: "perfilCliente", params: { id: this.chatChannel["mainMember.id"] } });
		} else {
			this.eventType = event;
			this.chatChannelModalIsOpen = true;
		}
	}

	closeModal() {
		this.chatChannelModalIsOpen = false;
	}

	formatStatusName(statusName: ChatChannelStatus) {
		if (statusName) {
			const dictionary = {
				[ChatChannelStatus.CLOSED]: "Encerrado",
				Encerrado: "Encerrado",
				[ChatChannelStatus.OPENED]: "Aberto",
				Aberto: "Aberto",
			};

			return dictionary[statusName];
		}
	}

	onBack() {
		this.$router.back();
	}
}
