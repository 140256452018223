






















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomButton from "@/layouts/components/Button.vue";
import Select from "@/layouts/components/Select.vue";
import Input from "@/layouts/components/Input.vue";
import { formatDateToISO, toIsoDate } from "@/helpers/calendar";
import Calendar from "./Calendar.vue";
import { showErrorAlert } from "@/helpers";
import ChatModel, { GetMessagesDto } from "@/api/chat.model";
import UserModel from "@/api/user.model";
import { CreateQueryParams } from "@nestjsx/crud-request";
import { IUser, UserRole } from "@/interfaces/user";
import CustomSelect from "@/layouts/components/Select.vue";
import debounce from "debounce";
import { ChatChannelStatus, ChatMessage } from "@/interfaces/chat";
import ChatMessageCard from "./ChatMessageCard.vue";
import MultipleSelectButton from "./MultipleSelectButton.vue";

@Component({
	components: {
		"custom-button": CustomButton,
		Select,
		"journey-input": Input,
		Calendar,
		"custom-select": CustomSelect,
		"chat-message-card": ChatMessageCard,
		"multiple-select-button": MultipleSelectButton,
	},
})
export default class ReportChatChannelModal extends Vue {
	@Prop() id: string;
	@Prop({ default: "" }) modalStyle!: string;
	@Prop({ default: "" }) title!: string;
	@Prop({ default: "" }) titlesStyle!: string;
	@Prop({ default: "" }) subtitle!: string;
	@Prop({ default: "" }) icon!: string;
	@Prop({ default: "" }) iconSize!: string;
	@Prop({ default: "mb-2" }) backButtonStyle!: string;
	@Prop({ default: "Voltar" }) backButtonText!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) withBackButton!: boolean;
	@Prop({ default: null }) onBackButton!: Function;
	@Prop({ default: () => [] }) buttons!: any[];
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop({}) emitEventName: string;
	@Prop({}) eventType!: string;
	@Prop({}) chatChannel!: any;

	isChatLoading = false;

	isModalOpen = this.isOpen;

	toIsoDate = toIsoDate;

	selectedDate: string = "";
	formattedSelectedDate: string = "";
	model = ChatModel;
	userModel = UserModel;

	userSearchParam: string = "";
	userList: { name: string; value: string; id: string }[] = [];

	selectedUser: any = {};

	userId: string = "";

	debouncedUserSearch = debounce(this.onSearch, 300);
	debouncedGetMoreMessages = debounce(this.loadMoreMessages, 300);

	shouldScrollBottom: boolean = true;

	chatMessages: ChatMessage[] = [];

	shouldSendFeedbackPoll: boolean = false;
	buttonOptions = [
		{ name: "Sim", value: true },
		{ name: "Não", value: false },
	];

	data() {
		return { isModalOpen: this.isOpen };
	}

	closeModal() {
		this.isModalOpen = false;
		if (this.onModalClosed && typeof this.onModalClosed === "function") {
			this.onModalClosed();
		}
	}
	@Watch("isOpen")
	async isOpenChanged() {
		this.isModalOpen = this.isOpen;
		if (this.isModalOpen) {
			try {
				switch (this.eventType) {
					case "transferChannel":
						await this.loadUsers();
						break;
					case "showChannelHistory":
						this.isChatLoading = true;
						this.chatMessages = (await this.getMessages({ chatChannel: this.chatChannel.id })).reverse();
						this.scrollToBottom();
						this.isChatLoading = false;
						break;
				}
			} catch (error) {
				showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
			}
		}
	}

	scrollToBottom() {
		setTimeout(() => {
			const element = document.getElementById("chatContainer");
			if (element && this.shouldScrollBottom) {
				element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			}
			this.shouldScrollBottom = true;
		}, 1000);
	}

	async created() {
		await this.isOpenChanged();
	}

	async loadUsers(name?: string, lastName?: string) {
		const queryParams: CreateQueryParams = this.getQueryParameters(name, lastName);
		const response = await this.userModel.search(queryParams);
		this.userList = response.data.length
			? response.data.map((user: IUser) => {
					return {
						name: `${user.name} ${user.lastName}`,
						value: user.id,
						id: user.id,
					};
			  })
			: this.userList;

		const foundUser = this.userList.find((user: any) => user.value === this.chatChannel["user.id"]);
		this.selectedUser = foundUser ?? {};
	}

	async loadMoreMessages() {
		try {
			const scrollContainerElement = document.getElementById("scrollContainer");
			if (scrollContainerElement && scrollContainerElement.scrollTop === 0) {
				scrollContainerElement.scrollTop = 10;
				const lastMessageCreatedDate = this.chatMessages[0].createdDate;
				const chatChannel = this.chatChannel.id;
				this.shouldScrollBottom = false;
				const foundMessages = (
					await this.getMessages({ chatChannel, lastChatMessageDate: lastMessageCreatedDate })
				).reverse();
				if (foundMessages.length) {
					this.chatMessages.unshift(...foundMessages);
				}
			}
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	async inputChange(value: string) {
		this.userId = value;
	}

	async onSearch(search: string) {
		const [name, ...lastNameArray] = search.split(" ");
		const lastName =
			lastNameArray.length > 1 ? lastNameArray.join(" ") : lastNameArray.length ? lastNameArray[0] : undefined;
		await this.loadUsers(name, lastName);
	}

	async saveAction() {
		try {
			// if (this.chatChannel.status === ChatChannelStatus.OPENED) {
			switch (this.eventType) {
				case "transferChannel":
					if (this.userId.length && this.chatChannel["user.id"] !== this.userId) {
						await this.transferChannel(this.chatChannel.id, this.userId);
					}
					break;
				case "closeChannel":
					await this.closeChannel(this.chatChannel.id, this.shouldSendFeedbackPoll);
					break;
			}
			// Garantir persistência dos dados
			location.reload();
			// }
		} catch (error) {
			showErrorAlert((<any>error)?.message ?? "Ocorreu um erro. Tente novamente.");
		}
	}

	async transferChannel(chatChannelId: string, userId: string) {
		await this.model.transferChannel(chatChannelId, userId);
	}

	async closeChannel(chatChannelId: string, shouldSendFeedbackPoll: boolean) {
		await this.model.closeChannel(chatChannelId, shouldSendFeedbackPoll);
	}

	async getMessages(getMessagesDto: GetMessagesDto) {
		return this.model.getMessages(getMessagesDto);
	}

	getQueryParameters(name?: string, lastName?: string): CreateQueryParams {
		const $or = name ? [{ name: { $cont: name } }, { lastName: { $cont: lastName } }] : [];
		return {
			join: [],
			limit: 50,
			page: 1,
			search: {
				$or,
				role: {
					$in: [UserRole.HEALTH_PROMOTER],
				},
			},
			sort: [{ field: "name", order: "ASC" }],
		};
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	updateReturnDate(date: string) {
		this.formattedSelectedDate = formatDateToISO(date);
	}
}
